* {
  font-family: 'Noto Sans JP', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  line-height: 1;
}

button {
  cursor: pointer;
}

svg,
img {
  max-width: 100%;
  vertical-align: bottom;
}

a {
  text-decoration: none;
  color: inherit;
}